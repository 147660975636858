// import 'babel-polyfill'
import { put, take, takeEvery, call, select, fork, delay, cancelled } from 'redux-saga/effects';
import { eventChannel, buffers } from 'redux-saga';
import { ajax_post, ajax_get } from '../api/util';
import {
  ADD_TASK_REQUEST,
  addTaskFailed,
  addTaskSuccess,
  putTaskToUI,
  removeTaskFromUI,
  COMPLETE_TASK_REQUEST,
  completeTaskFailed,
  completeTaskSuccess,
  UNCOMPLETE_TASK_REQUEST,
  uncompleteTaskFailed,
  uncompleteTaskSuccess,
  START_TASKS_WATCHER,
  initialTasksLoad,
  externalTasksUpdate,
  ADD_LIST_REQUEST,
  addListRequest,
  addListSuccess,
  addListFailed,
  INITIAL_CALENDAR_LOAD,
} from '../actions';
import { getEnabledCalendars } from 'reducers/calendar';
import { highestItemOrderInList, ObjectValues, localStorageGetItem, localStorageRemoveItem } from 'common/utils';
import { getDatabase, ref, onChildAdded, onChildChanged, onChildRemoved, onValue } from 'firebase/database';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import isDeepEqual from 'lodash.isequal';
import { refreshTasksPredictions } from 'api';
import { fetchNextRecurringEventUncompletedInstances } from 'api/calendar';
import { fbOps, saveToUserProfile } from 'common/firebase';
import Analytics from '../analytics.jsx';
import moment from 'moment';
import { isRecurringTask } from 'shared';

const db = getDatabase();
const dbFirestore = getFirestore();

export function* addTask(action) {
  const { tempId, title } = action;
  // console.log('tasksSaga', action)
  const stateTasks = yield select((state) => state.tasks),
    allTasks = ObjectValues(stateTasks.tasksObj);

  let listId = action.listId || stateTasks.defaultListId;

  const item_order = 1 + highestItemOrderInList(allTasks, listId);

  try {
    yield put(putTaskToUI({ title, listId, item_order, tempId, inProgress: true }));

    const data = yield call(ajax_post, '/api/task', {
      task: {
        tempId,
        title,
        listId,
        item_order,
      },
    });

    yield put(addTaskSuccess({ ...action, listId }, data.id, true));
    // yield put(putTaskToUI({ id: data.id, inProgress: false }));
  } catch (err) {
    yield put(addTaskFailed({ ...action, listId }, err));
    yield put(removeTaskFromUI(undefined, tempId));
  }
}

export function* changeTaskCompletion(action) {
  const { id, due, eventId } = action;
  const completed = action.type === COMPLETE_TASK_REQUEST;

  const task = yield select((state) => state.tasks.tasksObj[id]);
  const isRecurring = isRecurringTask(task);

  // recurring todoist tasks just update the due date
  // temporary set such task as completed but avoid setting inProgress
  // and let the external update set the correct state
  const inProgressIfNeeded = isRecurring ? false : true;

  yield put(putTaskToUI({ ...action, completed, inProgress: inProgressIfNeeded }));

  try {
    yield call(ajax_post, '/api/task/change_complete', { task: { id, completed, isRecurring, due, eventId } });

    const successAction = completed ? completeTaskSuccess(action) : uncompleteTaskSuccess(action);
    yield put(successAction);

    if (!isRecurring)
      // rely on external update for recurring
      yield put(putTaskToUI({ ...action, completed, inProgress: false }));
  } catch (err) {
    const failAction = completed ? completeTaskFailed(action, err) : uncompleteTaskFailed(action, err);
    yield put(failAction);
    yield put(putTaskToUI({ ...action, completed: !completed, inProgress: false }));
  }
}

export function* addList(action) {
  const { tempId, name, colorId, origin, isDefault } = action;
  try {
    yield call(ajax_post, '/api/list', { list: { tempId, name, colorId, origin, default: isDefault || null } });

    yield put(addListSuccess(action));
  } catch (err) {
    yield put(addListFailed(action, err));
  }
}

let firstTasksReload,
  listsObj = {},
  tasksObj = {},
  previousTasksObj = {},
  filtersObj = {};

// function* changeListEnabled(action) {
//   let { listId, enabled, legacy_id } = action;
//   yield put(actions.changeListEnabled(listId, enabled, legacy_id))
//   yield call(ajax_post, '/api/list/set_visibility', { listsIds: [listId], enabled });
// }

async function migrateListsVisibilityIfNeeded() {
  const valStr = localStorageGetItem('disabledTaskLists');
  if (!valStr) return;
  try {
    console.log('migrateListsVisibilityIfNeeded', valStr);
    const disabledListsIds = JSON.parse(valStr);
    await ajax_post('/api/list/bulk_set_visibility', {
      listsIds: disabledListsIds,
      enabled: false,
      reverseSkipped: true,
    });
    localStorageRemoveItem('disabledTaskLists');
    console.log('migrateListsVisibilityIfNeeded done');
  } catch (err) {
    console.log('migrateListsVisibilityIfNeeded error', err);
  }
}

// const realUpdateTasks = () => {
//     updateTasksTimeout = null

//     if(!firstTasksReload) {
//         firstTasksReload = true
//         yield put(initialTasksLoad(listsObj, tasksObj, disabledListsObj))
//     } else {
//         yield put(externalTasksUpdate(listsObj, tasksObj))
//     }

//     listsObj = {}
//     tasksObj = {}
// }
// const updateTasksWithDelay = () => {
//     if(!updateTasksTimeout) {
//         updateTasksTimeout = setTimeout(realUpdateTasks, 300)
//     }
// }

let refreshPredictionsInProgress = false;

function* executeRefreshTasksPredictions(tasksObj, listsObj, force = false) {
  const tasks = Object.values(tasksObj).filter(
    (t) =>
      !t.completed &&
      !t.removed &&
      !!t.title &&
      !(t.eventId || t.nextInstEventId) &&
      !t.duration &&
      listsObj[t.listId]?.enabled !== false &&
      listsObj[t.listId]?.removed !== true
  );
  if (!tasks.length && !force) return;

  const taskIds = tasks.map((task) => task.id);
  console.log('refreshTasksPredictions', tasks.length, taskIds);

  while (refreshPredictionsInProgress) {
    console.log('refreshPredictionsInProgress - delay');
    yield delay(2000);
  }

  try {
    refreshPredictionsInProgress = true;
    yield call(refreshTasksPredictions, taskIds);
    console.log('refreshTasksPredictions done');
    // const objWithPredictions = {};
    // predictions.forEach((prediction, i) => {
    //   objWithPredictions[tasks[i].id] = prediction;
    // });

    // yield put({ type: 'UPDATE_TASK_PREDICTIONS', predictionsObj: objWithPredictions });
  } catch (err) {
    console.log('refreshTasksPredictions error', err);
  } finally {
    refreshPredictionsInProgress = false;
  }
}

const RECURRING_RECOVERY_STEP = 1;

function* recoverRecurringTaskEventsWithoutNextInstEventId() {
  const user = yield select((state) => state.account.user);
  if (user.recurringRecoveryStep === RECURRING_RECOVERY_STEP) {
    console.log('recoverRecurringTaskEventsWithoutNextInstEventId: Already recovered');
    return;
  }
  const saveRecoveryStep = () => saveToUserProfile(user.uid, { recurringRecoveryStep: RECURRING_RECOVERY_STEP });

  // wait till calendars are loaded
  yield take(INITIAL_CALENDAR_LOAD);

  const stateTasks = yield select((state) => state.tasks);
  const defaultCalendarId = user.defaultCalendarId;
  if (!defaultCalendarId) {
    console.log('recoverRecurringTaskEventsWithoutNextInstEventId: No default calendar id');
    return;
  }

  const enabledCalendars = yield select(getEnabledCalendars);
  const sortedCalendarsIds = [];
  for (const cal of enabledCalendars) {
    if (
      cal.id === defaultCalendarId || // will be added first
      cal.pushNotificationsNotSupported || // probably don't have recurring events
      cal.canEdit === false // same
    ) {
      continue;
    }
    if (cal.isDefault) {
      sortedCalendarsIds.unshift(cal.id);
    } else {
      sortedCalendarsIds.push(cal.id);
    }
  }
  sortedCalendarsIds.unshift(defaultCalendarId);
  console.log('recoverRecurringTaskEventsWithoutNextInstEventId', {
    sortedCalendarsIds,
    enabledCalendars,
    defaultCalendarId,
  });

  const tasks = Object.values(stateTasks.tasksObj).filter(
    (t) => t.recurringEventIds && !t.nextInstEventId && !!t.eventBeginDate
  );
  if (!tasks.length) {
    console.log('recoverRecurringTaskEventsWithoutNextInstEventId: No tasks to recover');
    yield saveRecoveryStep();
    return;
  }
  Analytics.event({
    category: 'System',
    action: 'recoverRecurringTaskEventsWithoutNextInstEventId',
    label: `total: ${tasks.length}`,
  });

  console.log('recoverRecurringTaskEventsWithoutNextInstEventId', tasks);
  let resolved = 0;

  const resolvedTasksIds = {};

  for (const calendarId of sortedCalendarsIds) {
    for (const { id, recurringEventIds, eventBeginDate } of tasks) {
      if (resolvedTasksIds[id]) continue;

      const recurringEventIdsArr = Object.keys(recurringEventIds);

      for (const recurringEventId of recurringEventIdsArr) {
        try {
          const { instance } = yield call(
            fetchNextRecurringEventUncompletedInstances,
            calendarId,
            recurringEventId,
            moment(eventBeginDate).subtract(1, 'day').toISOString()
          );
          console.log(
            'recoverRecurringTaskEventsWithoutNextInstEventId: Next recurring instance for cal',
            calendarId,
            'task',
            id,
            ':',
            instance
          );
          if (instance) {
            const taskNextInstData = {
              nextInstEventId: instance.id,
              eventBeginDate: instance.beginDate,
              eventEndDate: instance.endDate,
            };
            console.log(
              'recoverRecurringTaskEventsWithoutNextInstEventId: Set next recurring instance ',
              taskNextInstData
            );
            yield fbOps.setTaskNextInst(id, taskNextInstData);

            resolved++;
            resolvedTasksIds[id] = true;
          }
        } catch (err) {
          console.log('recoverRecurringTaskEventsWithoutNextInstEventId error', err);
        }
      }
    }
  }
  Analytics.event({
    category: 'System',
    action: 'recoverRecurringTaskEventsWithoutNextInstEventId',
    label: `resolved: ${100 * (resolved / tasks.length)}%`,
  });

  yield saveRecoveryStep();
}

function* recoverRecurringWrongfullyCompletedTasks() {
  const user = yield select((state) => state.account.user);

  if (!user) {
    console.log('recoverRecurringWrongfullyCompletedTasks: No user');
    return;
  }

  const stateTasks = yield select((state) => state.tasks);

  const tasks = Object.values(stateTasks.tasksObj).filter(
    (t) => t.recurringEventIds && t.nextInstEventId && t.completed
  );
  if (!tasks.length) {
    console.log('recoverRecurringWrongfullyCompletedTasks: No tasks to recover');
    return;
  }

  console.log('recoverRecurringWrongfullyCompletedTasks', tasks);

  for (const task of tasks) {
    const { id, nextInstEventId } = task;
    const event = yield call(fbOps.getEventById, user.uid, nextInstEventId);
    if (!event) {
      console.log(
        'recoverRecurringWrongfullyCompletedTasks: Next recurring instance NOT found for task',
        id,
        task,
        '- just ignore'
      );
    } else if (!event?.taskCompleted) {
      console.log(
        'recoverRecurringWrongfullyCompletedTasks: Next recurring instance NOT completed for task - clear completed flag',
        id,
        event,
        task
      );
      yield fbOps.clearTaskCompletedFix(id);
    } else {
      console.log(
        'recoverRecurringWrongfullyCompletedTasks: Next recurring instance completed for task',
        id,
        task.title,
        event,
        task
      );
      try {
        const { instance } = yield call(
          fetchNextRecurringEventUncompletedInstances,
          event.calendarId,
          event.recurringEventId || Object.keys(task.recurringEventIds).pop(),
          moment(event.beginDate).subtract(1, 'day').toISOString()
        );
        console.log(
          'recoverRecurringWrongfullyCompletedTasks: Next recurring instance for cal',
          event.calendarId,
          'task',
          id,
          ':',
          instance
        );
        if (instance) {
          const taskNextInstData = {
            nextInstEventId: instance.id,
            eventBeginDate: instance.beginDate,
            eventEndDate: instance.endDate,
          };
          console.log('recoverRecurringWrongfullyCompletedTasks: Set next recurring instance ', taskNextInstData);
          yield fbOps.setTaskNextInst(id, taskNextInstData);
          console.log('recoverRecurringWrongfullyCompletedTasks: Clear task completed fix', id);
          yield fbOps.clearTaskCompletedFix(id);
        }
      } catch (err) {
        console.log('recoverRecurringWrongfullyCompletedTasks error', err);
      }
    }
  }
}

const isChanged = (stateTasks, listsObj, tasksObj) => {
  for (const key in listsObj) {
    if (!isDeepEqual(stateTasks.listsObj[key], listsObj[key])) {
      console.log('Lists not equal', stateTasks.listsObj[key], listsObj[key]);
      return true;
    }
  }
  for (const key in tasksObj) {
    if (!isDeepEqual(stateTasks.tasksObj[key], tasksObj[key])) {
      console.log('Tasks not equal', stateTasks.tasksObj[key], tasksObj[key]);
      return true;
    }
  }

  return false;
};

const fixBrokenRecurringReference = (task) => {
  if (!task.nextInstEventId) return;

  if (task.eventId) {
    // we shouldn't have eventId for recurring tasks
    console.log('fixBrokenRecurringReference: Remove eventId for recurring task-event', JSON.stringify(task));
    delete task.eventId;
  }
};

const setDefaultListVisibility = (list) => {
  if (list.enabled === undefined) list.enabled = true;
};

const normaliseDates = (task) => {
  if (task.eventBeginDate && task.eventBeginDate === task.eventEndDate && task.eventBeginDate.length === 10) {
    task.eventEndDate = moment(task.eventBeginDate).add(1, 'day').format('YYYY-MM-DD');
  }
};

const monitorPath = (path, onChange) => {
  const accumulatedObjects = {};
  const itemRef = ref(db, path);
  let initialLoadDone = false;
  let saveAndTriggerUpdate = (snapshot) => {
    if (!initialLoadDone) return; // ignore child added until initial load with 'value' is done

    let val = snapshot.val();
    accumulatedObjects[val.id] = val;
    onChange(accumulatedObjects, val);
  };

  // listsRef.off();
  onChildAdded(itemRef, saveAndTriggerUpdate);
  onChildChanged(itemRef, saveAndTriggerUpdate);
  onChildRemoved(itemRef, (snapshot) => {
    const val = snapshot.val();
    accumulatedObjects[snapshot.val().id] = {
      ...val,
      removed: true,
    };
    onChange(accumulatedObjects);
  });

  onValue(
    itemRef,
    (snapshot) => {
      initialLoadDone = true; // important to be before check if not empty
      const val = snapshot.val() || {};
      for (const it of Object.values(val)) {
        accumulatedObjects[it.id] = it;
      }
      onChange(accumulatedObjects);
    },
    (err) => {
      console.log('monitorPath error', err);
    },
    { onlyOnce: true }
  );
  return () => {
    itemRef.off();
  };
};

const getModifiedTasks = (tasksObj, previousTasksObj) => {
  const modifiedTasks = {};
  for (const key in tasksObj) {
    if (tasksObj[key] !== previousTasksObj[key]) {
      modifiedTasks[key] = tasksObj[key];
    }
  }
  return modifiedTasks;
};

function* taskslistsWatcher(uid) {
  let initialObjectivesLoadDone = false;

  migrateListsVisibilityIfNeeded();

  let channel = eventChannel((emit) => {
    const unsubFilters = monitorPath('filters/' + uid, (obj) => {
      filtersObj = obj;
      emit({});
    });
    const unsubLists = monitorPath('lists/' + uid, (obj) => {
      listsObj = obj;
      for (const list of Object.values(listsObj)) {
        setDefaultListVisibility(list);
      }
      emit({});
    });

    const unsubTasks = monitorPath('objectives/' + uid, (obj, item) => {
      tasksObj = obj;
      if (item) {
        fixBrokenRecurringReference(item);
        normaliseDates(item);
      } else {
        for (const key in tasksObj) {
          fixBrokenRecurringReference(tasksObj[key]);
          normaliseDates(tasksObj[key]);
        }
        initialObjectivesLoadDone = true;
      }
      emit({});
    });

    return () => {
      unsubFilters();
      unsubLists();
      unsubTasks();
    };
  }, buffers.dropping(1));

  while (true) {
    yield take(channel);
    yield delay(300);

    if (!initialObjectivesLoadDone) continue;

    yield fork(executeRefreshTasksPredictions, getModifiedTasks(tasksObj, previousTasksObj), listsObj);
    previousTasksObj = { ...tasksObj };

    if (!firstTasksReload) {
      firstTasksReload = true;

      if (Object.keys(listsObj).length === 0) {
        yield put(addListRequest(Date.now(), 'Inbox', 'default', 'trevor', true));
      }

      yield put(initialTasksLoad(listsObj, tasksObj, filtersObj));

      yield fork(recoverRecurringTaskEventsWithoutNextInstEventId);
      yield fork(recoverRecurringWrongfullyCompletedTasks);
    } else {
      const stateTasks = yield select((state) => state.tasks);
      if (isChanged(stateTasks, listsObj, tasksObj)) {
        yield put(externalTasksUpdate(listsObj, tasksObj, filtersObj));
      }
    }
  }
}

function* checkAndMonitorInitialModelTraining(uid) {
  console.log('Initial check for model');
  const channel = eventChannel((emit) => {
    return onSnapshot(
      doc(dbFirestore, 'user_models_training_requests', uid),
      (doc) => {
        emit(doc.data() || {});
      },
      (err) => {
        console.log('checkAndMonitorInitialModelTraining error', err);
      }
    );
  });

  let waitingForTraining = false;
  try {
    while (true) {
      const trainingRequest = yield take(channel);
      if (!trainingRequest?.last_trained_at) {
        if (!waitingForTraining) {
          // initiate training just in case
          yield fork(executeRefreshTasksPredictions, {}, {}, true);
          console.log('Waiting for model training');
          waitingForTraining = true;
        }
      } else {
        const { status, last_trained_at } = trainingRequest;
        console.log('Model training', status, last_trained_at);
        if (waitingForTraining) {
          waitingForTraining = false;
          const tasksObj = yield select((state) => state.tasks.tasksObj);
          const listsObj = yield select((state) => state.tasks.listsObj);
          console.log('Command initial predictions for tasks', tasksObj);
          yield fork(executeRefreshTasksPredictions, tasksObj, listsObj, true);
        }
        console.log('Stop initial monitoring for model training.');
        channel.close();
        break;
      }
    }
  } catch (err) {
    console.log('checkAndMonitorInitialModelTraining error', err);
  } finally {
    if (yield cancelled()) {
      console.log('Stop initial monitoring for model training - task cancelled');
      channel.close();
    }
  }
}

let watcher;
function* startTaskListsWatcher() {
  if (watcher) return;
  let user = yield select((state) => state.account.user);
  watcher = yield fork(taskslistsWatcher, user.uid);
  yield fork(checkAndMonitorInitialModelTraining, user.uid);
  try {
    yield ajax_get(`/api/refresh_tasks`);
  } catch (err) {
    console.log('refresh_tasks error', err);
    yield put({ type: 'REFRESH_TASKS_ERRORS', message: err.message || String(err) });
  }
}

// single entry point to start all Sagas at once
export default function* tasksSaga() {
  yield takeEvery(START_TASKS_WATCHER, startTaskListsWatcher);
  yield takeEvery(ADD_TASK_REQUEST, addTask);
  yield takeEvery(COMPLETE_TASK_REQUEST, changeTaskCompletion);
  yield takeEvery(UNCOMPLETE_TASK_REQUEST, changeTaskCompletion);
  // yield takeEvery(CHANGE_LIST_ENABLED, changeListEnabled);
  yield takeEvery(ADD_LIST_REQUEST, addList);
}
