import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { IconButton } from '@mui/material';
import './Changelog.css';
import PlaylistAdd from '@mui/icons-material/PlaylistAdd';
import ExternalIcon from '@mui/icons-material/OpenInNew';
import ActionEvent from '@mui/icons-material/Event';

const formatDate = (str) => moment(str).format('l');

const data = [
  [
    '2024-09-13',
    <>
      <p>
        <strong>Manage Scheduling Blocks</strong> to personalize the scheduling times for your task lists
      </p>
      <p>
        Create Scheduling Blocks in <Link to="/app/settings">Account Settings</Link> and link them to your lists,
        forcing Trevor's AI scheduling suggestions to respect your preferred scheduling times.
      </p>
      <p>
        A Scheduling Block specifies days of week and times of day to help organize your week. Ex: "Morning Routine",
        "Working Hours", "Side Project", "Thursday Gig", "Weekend Activities", etc...
      </p>
    </>,
  ],
  [
    '2024-09-13',
    <>
      <p>
        <strong>Select a Scheduling Calendar for each task list.</strong>
      </p>
      <p>
        You can now schedule personal tasks in a personal calendar and work tasks in a work calendar, giving you greater
        flexibiliy over your scheduling, on a per-list basis.
      </p>
      <p>Go to list settings (top right of task hub) and link your desired list to your desired calendar.</p>
    </>,
  ],
  [
    '2024-06-09',
    <>
      <p>
        <strong>Microsoft To Do integration is live!</strong>
      </p>
      <p>
        Trevor AI now integrates with Microsoft To do, allowing you to manage and schedule your tasks in realtime, with
        2-way sync. Connect via <Link to="/app/settings">Account Settings</Link> and leverage Trevor's AI scheduling for
        yet another task manager integration, with more to follow.
      </p>
      <p>Supported features include:</p>
      <ul>
        <li>Show Lists and To Dos (tasks) in the Task Hub</li>
        <li>Create, edit and delete tasks</li>
        <li>Complete and Un-complete tasks</li>
        <li>Reorder tasks in a list, only within Trevor AI</li>
        <li>Read the task’s due date</li>
        <li>Complete recurring tasks</li>
      </ul>
      <p>Not supported yet:</p>
      <ul>
        <li>Moving tasks between lists</li>
        <li>Subtasks</li>
        <li>Update due date when changed via Trevor AI</li>
        <li>Create recurring tasks via Trevor AI</li>
      </ul>
    </>,
  ],
  [
    '2024-04-05',
    <>
      <p>
        <strong>Say 'Hi' to Trevor AI - the GPT for Daily Planning!</strong>
      </p>
      <p>
        Trevor's new GPT-powered Chat marks a major milestone, beginning the transition from a{' '}
        <strong>smart planning interface</strong> into a <strong>personal planning assistant</strong>. An assistant that
        can privately request access to all necessary planning data - your tasks, calendar events and scheduling
        preferences, to execute complex and personal requests.
      </p>
      <p>Tap the chat icon at the bottom right and ask away! Here are a few examples:</p>
      <ul>
        <li>
          <strong>Add</strong> Reading for 30 min due tomorrow
        </li>
        <li>
          <strong>Schedule</strong> 5 tasks from my #work list
        </li>
        <li>
          <strong>Reschedule</strong> today's tasks for tomorrow
        </li>
        <li>
          <strong>When is</strong> my next dentist appointment?
        </li>
        <li>
          <strong>How to</strong> get started?
        </li>
        <li>
          <strong>Help me</strong> resolve a sync error with my Google calendar
        </li>
      </ul>
      <p>
        The current beta has a limit that resets daily, after which we will release an <strong>Ultra Plan</strong>,
        taking full advantage of the most powerful OpenAI models (GPT-4 and newer).
      </p>
      <small>
        <p>
          Trevor AI adopts a privacy-first approach, where he can only request access to data relevant to your request
          (like tasks and calendar events), but the data is only sent to GPT after your approval.
        </p>
        <p>
          “Ask Trevor” is built on OpenAI’s “GPT 4” or later, which they state is NOT shared with 3rd parties and is NOT
          used as training data - keeping your data private.
        </p>
        <p>Trevor AI never shares or sells any of your data to 3rd parties.</p>
      </small>
    </>,
  ],
  [
    '2023-12-02',
    <>
      <strong>Auto-scheduling with AI is live, in beta!</strong>
      <p>
        All of Trevor's <strong>Scheduling Suggestions</strong> in your timeline are powered by your personalized AI
        model that learns from your scheduling patterns.
        <br />
        <small>
          Your data is processed <strong>anonymously</strong> by Trevor's AI and will never be sold or shared with 3rd
          parties.
        </small>
      </p>
      Your personal AI model empowers:
      <ul>
        <li>Task duration predictions</li>
        <li>Suggestions for individual tasks, when expanded via the calendar icon</li>
        <li>Suggestions for overdue tasks, shown automatically</li>
        <li>
          Suggestions for tasks in the new <strong>Smart Scheduling Queue</strong>
        </li>
      </ul>
      <p>
        <strong>Smart Scheduling Queue</strong> is a powerful prioritization and scheduling technology. You can add
        individual tasks to the queue, via each task's scheduling icon, or add whole lists in bulk. Tasks with a due
        date are added automatically. Tasks from the queue leverage Trevor's AI and are intelligently mapped out in your
        timeline. To show the suggestions from your Scheduling Queue, click the new floating icon at the top left of
        your schedule.
      </p>
      <p>
        Scheduling Suggestions are not calendar events yet. You can either click on a suggestion to schedule it or you
        can schedule visible suggestions in bulk via the scheduling button in the floating menu.
      </p>
      <p>You can open and manage your scheduling queue via the lists dropdown at the top of your Task Hub.</p>
      <p>
        We're eager to hear about your AI scheduling experience -{' '}
        <a href="mailto:support@trevorlabs.com">support@trevorlabs.com</a>
      </p>
    </>,
  ],
  [
    '2023-07-31',
    <>
      Create new scheduled tasks with <strong>natural language date parsing</strong> from the add task field.{' '}
      <strong>"Meeting with John tomorrow at 14pm"</strong> will create a scheduled event with an associated task. Any
      date, without a time, will be scheduled in the all-day section, making it easier to drag it to the timeline later.
    </>,
  ],
  [
    '2023-06-10',
    <>
      <strong>The Calendar & Timeline</strong> have been completely overhauled, improving performance, ease of use and
      mobile usability. We've also added the ability to change calendar views on mobile, as well as a new scrollable
      date selector via the Date in the header.
      <br />
      <br />
      Trevor will now display <strong>Rescheduling Suggestions for Overdue Tasks</strong>, within your schedule. You
      will never forget a scheduled task again! Tapping on a suggestion presents the options to reschedule, mark as
      already completed, reschedule as unfinished and more. Overudue Suggestions are a paid Pro feature. You can
      customise them in <Link to="/app/settings">Account Settings</Link>.
      {/* <br />
      <br />
      Ask Trevor's AI via the new <strong>Automated Support Chat</strong> (at the bottom right). Trevor can instantly
      help you solve common problems, explain how the app works and connect you with the human support team if you
      mention 'human'. All you have to do is ask! You can always reach us directly at support@trevorai.com. */}
    </>,
  ],
  [
    '2023-03-07',
    <>
      You can now make <strong>Scheduled Tasks Recurring</strong> by tapping on a task in your schedule and selecting
      "Set Recurrence" from the event menu. Recurring scheduled tasks behave like recurring calendar events, but you can
      complete each individually to make sure you never miss an important task.
      <br />
      <br />
      You can also make a <strong>Recurring Task</strong> (unscheduled), via calendar icon next to each task in your
      Task Hub. This will allow you schedule it individually for each recurrence (daily, weekly, monthly, etc).
      <br />
      <br />
      <strong>Turn into Task</strong> allows you to instantly convert calendar events into scheduled tasks, especially
      useful for your existing recurring events.
      <br />
      <br />
      <strong>Smart Action Plan</strong> helps you to get started on your task by providing a list of suggested actions.
      Combined with a visually overhauled Event Menu, you can work on your task with improved clarity and focus.
    </>,
  ],
  [
    '2022-08-27',
    <>
      <strong>Microsoft Outlook integration</strong> is now available. Sync your Outlook calendars in real-time and
      choose between read-only access or full permission - giving you full control of your calendar via Trevor AI.
      <br />
      <br />
      Please note that Trevor Labs may be temporarily shown as an 'unverified' publisher in Outlook, but the integration
      is working as expected.
    </>,
  ],
  [
    '2022-03-24',
    <>
      Added the ability to enable <strong>multiple sign-in options</strong>, as well as to -{' '}
      <strong>sync multiple Google calendar accounts</strong>. Sync both your personal and professional calendars, in
      real-time, with this new Pro feature.
      <br />
      <br />
      Additionally free-tier users can now schedule an unlimited number of tasks per day, so feel free to{' '}
      <strong>invite friends and colleagues to collaborate on your #lists</strong>.
    </>,
  ],
  [
    '2022-02-21',
    <>
      Trevor’s AI will automatically assign a duration for each of your tasks to save you time and dramatically improve
      scheduling suggestions. Duration predictions are personalised to you, improve over time and can easily be changed
      manually. Further personalization available in <strong>Account Settings / Smart Scheduling behavior</strong>.
    </>,
  ],
  [
    '2021-11-26',
    <>
      Change the day your <strong>week starts on</strong> in <Link to="/app/calendar_options">Calendar Options</Link>,
      independently of Date and Time format.
    </>,
  ],
  [
    '2021-11-15',
    <>
      A fresh new look, improved usability and performance optimisations across nearly every component in Trevor's
      interface.
    </>,
  ],
  [
    '2021-09-07',
    <>
      Introduced paid <Link to="/app/upgrades">Pro</Link> and <Link to="/app/upgrades">Pro+</Link> plans, allowing you
      to upgrade your own Trevor. <Link to="/app/upgrades">Enable</Link> the “Scheduling”, “Organizational” and
      “Progress” modules to unlock your planning potential. Early bird users - registered before this date, already have
      those features enabled.
    </>,
  ],
  [
    '2021-08-16',
    <>
      Sync Todoist tasks’ Due Date when scheduled (or rescheduled) in Trevor - activate it from{' '}
      <strong>Account Settings / Todoist card</strong>.
    </>,
  ],
  [
    '2021-06-14',
    <>
      Monitor your progress and track how you invest your most valuable asset - your time. Access the new "My Progress"
      dashboard from the main menu or through <Link to="/app/progress">this link</Link>.
    </>,
  ],
  [
    '2021-04-27',
    <>
      Tasks scheduled into Google Calendar will now have link to Trevor - very convenient way from Google Calendar event
      back to Trevor task for additional actions. Applies for newly scheduled tasks only.
      <br />
      Imporoved Todoist integration:
      <br />
      - In addition to Today/Next 7 days we have added Tomorrow filter.
      <br />
      - Now it's possible to hide Todoist tasks assigned to other people and/or unassigned ones - activate it from
      Settings / Todoist card.
      <br />
    </>,
  ],
  [
    '2021-03-20',
    <>
      Quick suggestions became smart suggestions. <br />
      Tap the{' '}
      <IconButton>
        <ActionEvent />
      </IconButton>{' '}
      icon next to any of your tasks and Trevor AI will suggest available time slots in your calendar.
      <br />
    </>,
  ],
  [
    '2021-02-18',
    <>
      New UI Improvements for more intuitive navigation: <br />
      - Calendar Options moved to the main menu in the navigation bar.
      <br />
      - Calendar Views (Daily, 3 Day, Weekly & Monthly) moved to the navigation bar.
      <br />
      - "Go to today" button has it's own space in the nav bar and can be accessed on mobile in the bottom bar.
      <br />
      - Calendar navigation arrows moved to the left and right of the schedule. Keyboard arrows work the same.
      <br />- Calendar grid lines redesigned as hourly blocks.
    </>,
  ],
  [
    '2021-01-29',
    <>
      You can now navigate your schedule with the arrows on your keyboard. Left or Right to change the date; Up to
      select a date; Down to go to 'Today'
    </>,
  ],
  [
    '2021-01-25',
    <>
      You can now reorder your lists. Just open task lists settings
      <IconButton>
        <PlaylistAdd />
      </IconButton>{' '}
      and drag your lists around.
    </>,
  ],
  [
    '2020-12-25',
    <>
      Improved Todoist integration. Now completing your recurring Todoist task will make the task reappear in the Tasks
      panel with updated due date. You can also quickly go to your Todoist task or project - look for{' '}
      <IconButton>
        <ExternalIcon />
      </IconButton>{' '}
      appearing next to task or project name.
    </>,
  ],
];

export const getNewestChangelogDate = () => data[0][0];

export const Changelog = () => {
  return (
    <div className="changelog">
      {data.map(([date, content]) => (
        <div className="item" key={date}>
          <h3>{formatDate(date)}</h3>
          <p>{content}</p>
        </div>
      ))}
    </div>
  );
};
