import moment from 'moment';
import { UPDATE_LOCAL_PREFS, USER_DATA_CHANGED } from '../actions';
import isEqual from 'lodash.isequal';
import { ReduxState } from 'reducers';
import { createSelector } from 'reselect';
import { User, UserReadonlyDetails } from 'shared';

const defaultState: { user: User | undefined } = {
  user: undefined,
};

export const account = (state = defaultState, action) => {
  switch (action.type) {
    case USER_DATA_CHANGED:
      let { user } = action;
      if (!isEqual(state.user, user)) {
        return {
          ...state,
          user,
        };
      }
      break;
    case UPDATE_LOCAL_PREFS:
      return {
        ...state,
        user: {
          ...state.user,
          prefs: {
            ...state.user.prefs,
            ...action.prefs,
          },
        },
      };
    default:
  }
  return state;
};

export const getValidBoostersForCurrentDate = createSelector<
  ReduxState,
  ReduxState['account']['user'],
  UserReadonlyDetails['boosters']
>(
  (state) => state.account.user,
  (user) => {
    const dateNow = moment().format('YYYY-MM-DD');
    return user?.boosters?.filter((booster) => dateNow <= booster.validUntil);
  }
);
